import Profile from "../../common/model/Profile";
import ProfileFilter from "../../common/model/ProfileFilter";

interface InitialState {
  profileList: Profile[];
  filter: ProfileFilter;
  currentProfileIndex: number;
  offset: number;
  profileCount: number;
  newFetch: boolean;
  identity: string;
}

const initialState: InitialState = {
  profileList: [],
  filter: new ProfileFilter(),
  currentProfileIndex: 0,
  offset: 0,
  profileCount: 0,
  newFetch: false,
  identity: ""
};

export default initialState;
