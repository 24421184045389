import React, {useEffect} from 'react';
import './App.css';
import LandingContainer from "./landing/containers/LandingContainer";
import ProfileCardListContainer from "./social/containers/ProfileCardListContainer";
import ProfileViewContainer from "./social/containers/ProfileViewContainer";
import FilterContainer from "./social/containers/FilterContainer";
import Registered from './register/components/Registered';
import {isMobile} from 'react-device-detect';
import AnalyticsService from "./common/service/AnalyticsService";
import LoadingModal from "./common/components/LoadingModal";
import ReactModal from "react-modal";
import TermsOfUse from "./compliance/components/TermsOfUse";
import PrivacyPolicy from "./compliance/components/PrivacyPolicy";
import RefundPolicy from "./compliance/components/RefundPolicy";
import ContactUs from "./compliance/components/ContactUs";
import AboutUs from "./compliance/components/AboutUs";
import ProfileComponent from "./register/containers/ProfileContainer";
import ProfileContainer from "./register/containers/ProfileContainer";
import LoginContainer from "./register/containers/LoginContainer";

interface Props {
  location: string;
  loading: boolean;
}

function App(props: Props) {
  // @ts-ignore
  ReactModal.defaultStyles.overlay.backgroundColor = '#02084B';
  useEffect(() => {
    AnalyticsService
      .logEvent('device', isMobile ? "mobile" : "pc")
      .then(() => {})
  }, [])

  document.title = 'Vennila Matrimony';
  document.body.style.overflow = 'auto';
  // @ts-ignore
  document.getElementsByTagName('META')[2].content = "Tamil Nadu's safest matrimonial service";


  return (
    <div style={{height: "100vh", padding: 0, margin: 0}}>
      <LoadingModal isOpen={props.loading} />
      {
        (props.location === "/") ? <LandingContainer />
          : (props.location === "/social") ? <ProfileCardListContainer />
          : (props.location === "/filter") ? <FilterContainer />
          : (props.location === "/profile") ? <ProfileContainer />
          : (props.location === "/login") ? <LoginContainer />
          : (props.location === "/registered") ? <Registered />
          : (props.location === "/terms") ? <TermsOfUse />
          : (props.location === "/privacy-policy") ? <PrivacyPolicy />
          : (props.location === "/refund-policy") ? <RefundPolicy />
          : (props.location === "/contact-us") ? <ContactUs />
          : (props.location === "/about-us") ? <AboutUs />
          : <ProfileViewContainer />
      }
    </div>
  );
}

export default App;
