import React from 'react';

interface ActionButtonProps {
    text: string;
    onPress: () => void;
}

const ActionButton = (props: ActionButtonProps) => (
    <div onClick={props.onPress} style={styles.actionButton}>
        {props.text}
    </div>
);

const styles = {
    actionButton: {
        background: "#cb6ce6",
        padding: 7,
        borderRadius: 5,
        fontFamily: "'Dancing Script', cursive",
        fontSize: 15,
        fontWeight: "bold",
        width: "100%",
        textAlign: "center" as 'center',
        marginBottom: 20,
    }
}

export default ActionButton;
