import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../common/redux/store";
import {changeCaste, changeReligion, socialModeEntered} from "../redux/thunks";
import Filter from "../components/Filter";
import {changeIdentity} from "../redux/slice";

const mapStateToProps = (state: RootState) => ({
  caste: state.social.filter.casteList[0],
  religion: state.social.filter.religionList[0],
  identity: state.social.identity
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeCaste(caste: string) {
    dispatch(changeCaste(caste));
  },
  changeReligion(religion: string) {
    dispatch(changeReligion(religion));
  },
  changeIdentity(identity: string) {
    dispatch(changeIdentity(identity));
  },
  fetchProfiles() {
    dispatch(socialModeEntered());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
