import React, {useState} from "react";
import ProfileField from "./ProfileField";
import backgroundImage from "../../assets/bg.png";
import Profile from "../../common/model/Profile";
import ActionButton from "../../common/components/ActionButton";
import DateManipulator from "../../common/model/DateManipulator";
import PayModal from "./PayModal";

interface Props {
  profile: Profile;
  navigateToCards: () => void;
  getNumber: (profile: Profile) => void;
}

export default function ProfileView(props: Props) {
  const [modal, setModal] = useState<boolean>(false);

  return (
    <div style={styles.container}>
      <PayModal isOpen={modal} closeModal={() => {setModal(false)}} onClick={() => {props.getNumber(props.profile)}} />
      <h1 style={styles.heading}>Vennila Matrimony</h1>
      <div style={styles.profileCard}>
        <p>{props.profile.name}</p>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Father'}
            value={props.profile.father}
          />
          <ProfileField
            name={'Mother'}
            value={props.profile.mother}
          />
        </div>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Height'}
            value={props.profile.height + 'cm'}
          />
          <ProfileField
            name={'Date of Birth'}
            value={DateManipulator.formatDate(
              new Date(props.profile.dateOfBirth),
            )}
          />
        </div>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Brothers'}
            value={props.profile.brothers.toString()}
          />
          <ProfileField
            name={'Married'}
            value={props.profile.brothersMarried.toString()}
          />
          <ProfileField
            name={'Sisters'}
            value={props.profile.sisters.toString()}
          />
          <ProfileField
            name={'Married'}
            value={props.profile.sistersMarried.toString()}
          />
        </div>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Religion'}
            value={props.profile.religion}
          />
          <ProfileField
            name={'Caste'}
            value={props.profile.caste}
          />
          <ProfileField
            name={'Star'}
            value={props.profile.star}
          />
        </div>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Monthly Income'}
            value={props.profile.salary ? props.profile.salary.toString() : '--'}
          />
          <ProfileField
            name={'Working In'}
            value={props.profile.company}
          />
        </div>
      </div>
      <div style={styles.buttonContainer}>
        <ActionButton onPress={props.navigateToCards} text={"Back"} />
        <ActionButton onPress={() => {props.getNumber(props.profile)}} text={"Mobile Number"} />
      </div>
    </div>
  )
}

const styles = {
  fieldRow: {
    display: "flex",
    flexDirection: 'row' as "row",
    marginBottom: 20
  },
  heading: {
    marginTop: 40,
    fontFamily: "'Dancing Script', cursive"
  },
  profileCard: {
    padding: 10,
    background: "#603693",
    margin: 10,
    borderRadius: 10
  },
  container: {
    backgroundImage: `url(${backgroundImage})`,
    height: "100%",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    padding: 20
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    width: '100%',
    justifyContent: 'space-evenly'
  }
}

