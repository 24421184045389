import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import initialState from './initialState';
import Profile from "../../common/model/Profile";

const Slice = createSlice({
  name: 'Profile',
  initialState,
  reducers: {
    changeMobileNumber(state, action: PayloadAction<string>) {
      const regex = /^[0-9]{0,10}$/
      if (regex.test(action.payload)) {
        state.mobileNumber = action.payload;
      }
    },
    changeCode(state, action: PayloadAction<string>) {
      const regex = /^[0-9]{0,4}$/
      if (regex.test(action.payload)) {
        state.code = action.payload;
      }
    },
    changeJWT(state, action: PayloadAction<string>) {
      state.jwt = action.payload;
    },
    changeRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    changeProfile(state, action: PayloadAction<Profile>) {
      state.profile = action.payload;
      state.modifiedProfile = action.payload;
    },
    changeModifiedProfile(state, action: PayloadAction<Profile>) {
      state.modifiedProfile = action.payload;
    },
    changeEditMode(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
    changeDeactivationConfirmationOpen(state, action: PayloadAction<boolean>) {
      state.deactivationConfirmationOpen = action.payload;
    },
  },
});

export default Slice.reducer;

export const {
  changeJWT,
  changeRefreshToken,
  changeCode,
  changeMobileNumber,
  changeEditMode,
  changeDeactivationConfirmationOpen,
  changeModifiedProfile,
  changeProfile,
} = Slice.actions;