import {combineReducers} from '@reduxjs/toolkit';
import SocialReducer from "../../social/redux/slice"
import RegisterReducer from "../../register/redux/slice"
import CommonReducer from "./slice"

const rootReducer = combineReducers({
  social: SocialReducer,
  register: RegisterReducer,
  common: CommonReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
