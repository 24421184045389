import React from 'react';

export default function ContactUs() {
  return (
    <div style={styles.container}>
      <h1>Contact Us</h1>
      <br />
      <p style={styles.text}>
        Flutoz Software OPC Private Limited, <br />
        Plot 219/1, 17th Street, South Balabagyanagar, <br />
        Tirunelveli Junction, Tirunelveli,
        <br />
        Tamil Nadu - 627001 <br />
        Mobile Number: 9597525759 <br />
        Email: support@flutoz.com
      </p>
    </div>
  );
}

const styles = {
  text: {
    color: 'white',
    textAlign: 'justify' as 'justify',
    fontWeight: 200,
    letterSpacing: 1,
    fontSize: '2vh',
  },
  container: {
    color: '#0ca950',
    backgroundColor: '#323337',
    padding: 50,
    minHeight: '100%',
  },
};
