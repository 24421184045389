import store from "../common/redux/store";
import {changeLocation} from "../common/redux/slice";

class RegisterNavigation {
  navigateToProfile() {
    store.dispatch(changeLocation("/profile"));
  }
  navigateToRegistered() {
    store.dispatch(changeLocation("/registered"))
  }
  navigateToLogin() {
    store.dispatch(changeLocation("/login"))
  }
}

export default new RegisterNavigation()
