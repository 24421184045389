import React from 'react';

export default function RefundPolicy() {
  return (
    <div style={styles.container}>
      <h1>Refund Policy and Limitation of liability</h1>
      <p style={styles.text}>
        The company or its Office bearers or employees shall under no
        circumstances be liable or responsible to the Neetbio user or his/her
        authorized representative or any third party for any direct, indirect,
        special, exemplary, incidental, or consequential damages if any
        Notwithstanding anything to the contrary contained herein, the company’s
        liability to Neetbio user for any cause whatsoever, and regardless of
        the form of the action, will at all times be limited to the amount paid
        by the user to the company for the specific instance of test, and no
        further.
      </p>
    </div>
  );
}

const styles = {
  text: {
    color: 'white',
    textAlign: 'justify' as 'justify',
    fontFamily: "Teko, 'normal'",
    fontWeight: 200,
    letterSpacing: 1,
    fontSize: '1.2vw',
    lineHeight: 1,
  },
  subheading: {
    // color: '#0ca950',
    color: 'white',
    fontFamily: "Teko, 'normal'",
    letterSpacing: 1,
    fontSize: '1.5vw',
  },
  container: {
    color: '#0ca950',
    backgroundColor: '#323337',
    padding: 50,
    minHeight: '100%',
  },
};
