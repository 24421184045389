import React from "react";
import styles from "./styles";

interface Props {
    label: string;
    value: string;
    valueList: string[];
    onChange: (value: string) => void;
}

export default function Select(props: Props): JSX.Element {
    return (
        <div style={{width:'100%'}}>
            <div style={styles.inputHeader}>{props.label}</div>
            <select
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value)
                }}
                style={selectStyles.input}
            >
                <option value={'-'}>-</option>
                {props.valueList.map((caste: string) => <option value={caste}>{caste}</option>)}
            </select>
        </div>
    )
}

const selectStyles = {
    input: {
        ...styles.input,
        width: "105%"
    }
}
