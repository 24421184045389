import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../common/redux/store";
import {changeCurrentProfileIndexThunk, fetchMobile, refreshProfileList, socialModeEntered} from "../redux/thunks";
import ProfileCardList from "../components/ProfileCardList";
import SocialNavigation from "../SocialNavigation";
import Profile from "../../common/model/Profile";

const mapStateToProps = (state: RootState) => ({
  profileList: state.social.profileList,
  currentProfileIndex: state.social.currentProfileIndex,
  profileCount: state.social.profileCount,
  offset: state.social.offset,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  socialModeEntered() {
    dispatch(socialModeEntered());
  },
  refreshList() {
    dispatch(refreshProfileList());
  },
  changeCurrentPositionIndex(index: number) {
    dispatch(changeCurrentProfileIndexThunk(index));
  },
  viewProfile: SocialNavigation.navigateToProfileView,
  getNumber: (profile: Profile) => {
    dispatch(fetchMobile(profile))
  },
  navigateToFilter: SocialNavigation.navigateToFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardList);
