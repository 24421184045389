import Controller from "./Controller";
import store from "../redux/store";

class AnalyticsService {
  async logEvent(name: string, payload: any): Promise<void> {
    const id = store.getState().common.id
    // await Controller.fetchVoid('/analytics/log', { id, name, payload });
  }
}

export default new AnalyticsService()
