import {AppDispatch, AppThunk, RootState} from "../../common/redux/store";
import Profile from "../../common/model/Profile";
import {changeCurrentProfileIndex, changeFilter, changeOffset, changeProfileCount, changeProfileList} from "./slice";
import ProfileFilter from "../../common/model/ProfileFilter";
import SocialService from "../../common/service/SocialService";
import {batch} from 'react-redux';
import SocialNavigation from "../SocialNavigation";
import AnalyticsService from "../../common/service/AnalyticsService";
import {changeLoading} from "../../common/redux/slice";

export const formatFilter = (filter: ProfileFilter) => {
  filter = {
    ...filter,
    gender: 'M'
  };
  if (filter.maxAge === 41) {
    filter.maxAge = 120;
  }
  // if (!filter.casteList.length) {
  //   filter = {
  //     ...filter,
  //     // @ts-ignore
  //     casteList: profile.caste
  //       ? [profile.caste]
  //       : casteList
  //         .filter((data: SelectData) => data.value)
  //         .map((data: SelectData) => data.value),
  //   };
  // }
  // if (!filter.religionList.length) {
  //   filter = {
  //     ...filter,
  //     // @ts-ignore
  //     religionList: profile.religion
  //       ? [profile.religion]
  //       : religionList
  //         .filter((data: SelectData) => data.value)
  //         .map((data: SelectData) => data.value),
  //   };
  // }
  // if (!filter.starList.length) {
  //   filter = {
  //     ...filter,
  //     // @ts-ignore
  //     starList: starList
  //       .filter((data: SelectData) => data.value)
  //       .map((data: SelectData) => data.value),
  //   };
  // }
  // if (!filter.qualificationList.length) {
  //   filter = {
  //     ...filter,
  //     // @ts-ignore
  //     qualificationList: qualificationList
  //       .filter((data: SelectData) => data.value)
  //       .map((data: SelectData) => data.value),
  //   };
  // }
  // if (!filter.occupationTypeList.length) {
  //   filter = {
  //     ...filter,
  //     // @ts-ignore
  //     occupationTypeList: occupationTypeList
  //       .filter((data: SelectData) => data.value)
  //       .map((data: SelectData) => data.value),
  //   };
  // }
  return filter;
};


export const socialModeEntered = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeOffset(0));
  dispatch(changeCurrentProfileIndex(0));
  dispatch(fetchProfiles());
};

const fetchProfiles = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(changeLoading(true));
  const state: RootState = getState();
  const filter: ProfileFilter = state.social.filter;
  const formattedFilter: ProfileFilter = formatFilter(filter);
  const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(
    formattedFilter,
    state.social.offset,
  );
  const profileCountPromise: Promise<number> = SocialService.fetchProfileCount(
    formattedFilter,
  );
  await AnalyticsService.logEvent("filter", formattedFilter);
  await AnalyticsService.logEvent("identity", state.social.identity);
  const profileList: Profile[] = await profileListPromise;
  const profileCount: number = await profileCountPromise;
  batch(() => {
    dispatch(changeProfileList(profileList));
    dispatch(changeProfileCount(profileCount));
  });
  dispatch(changeLoading(false));
  if (profileCount === 0) {
    alert("No matching profiles found. Please try with different caste/religion.");
    return;
  }
  SocialNavigation.navigateToProfileCardList();
};

export const changeCaste = (caste: string): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state: RootState = getState();
  const filter: ProfileFilter = state.social.filter;
  let casteList: string[] = [];
  if (caste !== "Any") {
    casteList = [caste];
  }
  const modifiedFilter: ProfileFilter = {...filter, casteList};
  dispatch(changeFilter(modifiedFilter));
};

export const changeReligion = (religion: string): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state: RootState = getState();
  const filter: ProfileFilter = state.social.filter;
  let religionList: string[] = [];
  if (religion !== "Any") {
    religionList = [religion];
  }
  const modifiedFilter: ProfileFilter = {...filter, religionList};
  dispatch(changeFilter(modifiedFilter));
};

export const fetchMobile = (profile: Profile): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  await AnalyticsService.logEvent("fetchMobile", profile.id)
  await SocialService.fetchMobile(profile.id);
};

export const refreshProfileList = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state: RootState = getState();
  const filter: ProfileFilter = state.social.filter;
  const profileCountPromise: Promise<number> = SocialService.fetchProfileCount(
    formatFilter(filter),
  );
  const profileCount: number = await profileCountPromise;
  if (profileCount !== state.social.profileCount) {
    const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(
      formatFilter(filter),
      state.social.offset,
    );
    const profileList: Profile[] = await profileListPromise;
    batch(() => {
      dispatch(changeProfileList(profileList));
      dispatch(changeProfileCount(profileCount));
    });
  }
};

export const changeCurrentProfileIndexThunk = (
  index: number,
): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state: RootState = getState();
  const offset: number = state.social.offset;
  if (index === -1) {
    if (offset) {
      const newOffset: number = Math.max(0, offset - 100);
      const filter: ProfileFilter = formatFilter(state.social.filter);
      const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(filter, newOffset);
      const countPromise: Promise<number> = SocialService.fetchProfileCount(filter);
      const profileList: Profile[] = await profileListPromise;
      const profileCount: number = await countPromise;
      batch(() => {
        dispatch(changeProfileList(profileList));
        dispatch(changeProfileCount(profileCount));
        dispatch(changeOffset(newOffset));
        dispatch(changeCurrentProfileIndex(profileList.length - 1));
      });
    }
  } else if (index >= 100) {
    if (state.social.profileList.length + state.social.offset < state.social.profileCount) {
      const newOffset: number = offset + 100;
      const filter: ProfileFilter = formatFilter(state.social.filter);
      const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(filter, newOffset);
      const countPromise: Promise<number> = SocialService.fetchProfileCount(filter);
      const profileList: Profile[] = await profileListPromise;
      const profileCount: number = await countPromise;
      batch(() => {
        dispatch(changeProfileList(profileList));
        dispatch(changeProfileCount(profileCount));
        dispatch(changeOffset(newOffset));
        dispatch(changeCurrentProfileIndex(0));
      });
    }
  } else {
    dispatch(changeCurrentProfileIndex(index));
  }
  AnalyticsService
    .logEvent("profileView", true)
    .then(() => {})
};
