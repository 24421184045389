import React from "react";
import styles from "./styles";

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
}

export default function TextInput(props: Props): JSX.Element {
    return (
        <div style={{width:'100%'}}>
            <div style={styles.inputHeader}>{props.label}</div>
            <input
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value)
                }}
                style={styles.input}
            />
        </div>
    )
}
