import React from "react";
import styles from "./styles";

interface Props {
    label: string;
    value: number;
    onChange: (value: number) => void;
}

export default function NumericInput(props: Props): JSX.Element {
    return (
        <div style={{width:'100%'}}>
            <div style={styles.inputHeader}>{props.label}</div>
            <input
                type="number"
                value={props.value}
                onChange={(e) => {
                    const value: number = Number(e.target.value);
                    props.onChange(value)
                }}
                style={styles.input}
            />
        </div>
    )
}
