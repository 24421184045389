import {connect} from 'react-redux';
import Landing from "../components/Landing";
import SocialNavigation from "../../social/SocialNavigation";
import RegisterNavigation from "../../register/RegisterNavigation";
import AnalyticsService from "../../common/service/AnalyticsService";

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  navigateToRegister() {
    AnalyticsService
      .logEvent("gender", "male")
      .then(() => {})
    RegisterNavigation.navigateToLogin()
  },
  navigateToSocial() {
    AnalyticsService
      .logEvent("gender", "female")
      .then(() => {})
    SocialNavigation.navigateToFilter()
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
