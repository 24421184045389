import * as React from 'react';
import Profile from "../../common/model/Profile";
import DateManipulator from "../../common/model/DateManipulator";
import ProfileField from "./ProfileField";

interface Props {
  profile: Profile;
  navigateToProfileView: () => void;
  getNumber: (profile: Profile) => void;
}

export default function ProfileCard(props: Props) {
  return (
    <div style={styles.container}>
      <p style={{fontWeight: "bold", fontSize: 18, alignSelf: "center"}}>{props.profile.name}</p>
      <div>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Date of Birth'}
            value={DateManipulator.formatDate(
              new Date(props.profile.dateOfBirth),
            )}
          />
          <ProfileField
            name={'Star'}
            value={props.profile.star}
          />
        </div>
        <div style={styles.fieldRow}>
          <ProfileField
            name={'Current Location'}
            value={props.profile.workLocation ? props.profile.workLocation + ' ' : ' '}
          />
          <ProfileField
            name={'Height'}
            value={props.profile.height + ' cm'}
          />
        </div>
        <div style={{marginBottom: 20}}>
          <ProfileField
            name={'Qualification'}
            value={props.profile.educationalQualification}
          />
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginBottom: 20, width: "100%"}}>
        <ActionButton
          onPress={props.navigateToProfileView}
          text={'More Details'}
        />
        <ActionButton
          onPress={() => {
            props.getNumber(props.profile);
          }}
          text={'Mobile Number'}
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: 10,
    background: "#603693",
    margin: 10,
    borderRadius: 10,
    width: "85%"
  },
  fieldRow: {
    display: "flex",
    flexDirection: 'row' as "row",
    marginBottom: 20
  },
  actionButton: {
    background: "#cb6ce6",
    padding: 10,
    borderRadius: 5,
    fontFamily: "'Dancing Script', cursive",
    fontSize: 24,
    fontWeight: "bold" as "bold"
  }
}

const ActionButton = (props: ActionButtonProps) => (
  <div style={styles.actionButton} onClick={props.onPress}>
      {props.text}
  </div>
);

interface ActionButtonProps {
  icon?: string;
  text: string;
  onPress: () => void;
  color?: string;
}
