import {MobileNumberInvalidException, CodeInvalidException} from '../exceptions';
import {jwtDecode} from 'jwt-decode';
import Controller from "./Controller";
import store, {RootState} from "../redux/store";
import {changeJWT, changeRefreshToken} from "../../register/redux/slice";
import AuthenticationResponse from "../model/AuthenticationReponse";

class AuthenticationService {
    private static validateMobileNumber(mobileNumber: string): void {
        if (mobileNumber.length !== 10) {
            throw MobileNumberInvalidException;
        }
    }

    private static validateCode = (code: string): void => {
        if (code.length !== 4) {
            throw CodeInvalidException;
        }
    };

    sendOTP = async (mobileNumber: string): Promise<void> => {
        console.log(`Sending an OTP to ${mobileNumber}`)
        AuthenticationService.validateMobileNumber(mobileNumber);
        await Controller.fetchVoid('/authentication/request', {mobileNumber});
        console.log(`OTP sent to ${mobileNumber} successfully`)
    };

    attemptLoginMobile = async (
        mobileNumber: string,
        confirmationCode: string,
    ): Promise<AuthenticationResponse> => {
        AuthenticationService.validateMobileNumber(mobileNumber);
        AuthenticationService.validateCode(confirmationCode);
        return Controller.fetch('/authentication/confirm', {
            mobileNumber,
            confirmationCode,
        });
    };

    getUserId(state: RootState): string | null {
        if (
            state.register.jwt &&
            jwtDecode(state.register.jwt) &&
            // @ts-ignore
            jwtDecode(state.register.jwt)?.userId
        ) {
            // @ts-ignore
            return jwtDecode(state.register.jwt)?.userId;
        } else {
            return null;
        }
    }

    async renewJWT(): Promise<void> {
        const authResponse: AuthenticationResponse = await Controller.fetch(
            '/authentication/renew',
            {
                refreshToken: store.getState().register.refreshToken,
            },
        );
        store.dispatch(changeJWT(authResponse.jwt));
        store.dispatch(changeRefreshToken(authResponse.refreshToken));
    }
}

export default new AuthenticationService();