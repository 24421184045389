import Profile from "../../common/model/Profile";

interface InitialState {
  mobileNumber: string;
  code: string;
  jwt: string | null;
  refreshToken: string | null;
  profile: Profile;
  modifiedProfile: Profile;
  editMode: boolean;
  deactivationConfirmationOpen: boolean;
}

const initialState: InitialState = {
  mobileNumber: "",
  code: "",
  jwt: null,
  refreshToken: null,
  profile: new Profile(),
  modifiedProfile: new Profile(),
  editMode: false,
  deactivationConfirmationOpen: false,
};

export default initialState;
