import {connect} from 'react-redux';
import {AppDispatch, RootState} from "./common/redux/store";
import App from "./App";

const mapStateToProps = (state: RootState) => ({
  location: state.common.location,
  loading: state.common.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
