const casteList: string[] = [
  // 'Any',
  'Adaviyar',
  'Adi Dravidar',
  'Agamudayar',
  'Ambalakarar',
  'Ambattar',
  'Ansari',
  'Arain',
  'Arayar',
  'Arunthathiyar',
  'Ashtasahasram',
  'Awan',
  'Ayira Vysya',
  'Badagar',
  'Bhatraju',
  'Bohra',
  'Boyar',
  'Brahmin - Gurukkal',
  'Brahmin - Iyengar',
  'Brahmin - Iyer',
  'Brahmin - Others',
  'Chattada Sri Vaishnava',
  'Chavalakarar',
  'Chettiar - 24 Manai Telugu Chettiar',
  'Chettiar - Achirapakkam Chettiar',
  'Chettiar - Agaram Vellan Chettiar',
  'Chettiar - Beri Chettiar',
  'Chettiar - Devanga Chettiar',
  'Chettiar - Kongu Chettiar',
  'Chettiar - Manjapudur Chettiar',
  'Chettiar - Nattukottai Chettiar',
  'Chettiar - Others',
  'Chettiar - Pannirandam Chettiar',
  'Chettiar - Saiva Vellan chettiar',
  'Chettiar - Vaniya Chettiar',
  'Chetty - Elur Chetty',
  'Chetty - Kottar Chetty',
  'Chetty - Kuruhini Chetty',
  'Chetty - Moundadan Chetty',
  'Chetty - Pathira Chetty',
  'Chetty - Pudukkadai Chetty',
  'Chetty - Sadhu Chetty',
  'Chetty - Sozhia Chetty',
  'Chetty - Valayal Chetty',
  'Dasapalanjika',
  'Dekkani',
  'Desikar',
  'Desikar Thanjavur',
  'Dudekula',
  'Enadi',
  'Eravallar',
  'Gounder - Anuppa Vellala Gounder',
  'Gounder - Chendalai Gounder',
  'Gounder - Kalveli Gounder',
  'Gounder - Kongu Vellala Gounder',
  'Gounder - Kurumba Gounder',
  'Gounder - Narambukkatti Gounder',
  'Gounder - Nattu Gounder',
  'Gounder - Others',
  'Gounder - Padaithalai Gounder',
  'Gounder - Pala Gounder',
  'Gounder - Pala Vellala Gounder',
  'Gounder - Pavalankatti Vellala Gounder',
  'Gounder - Poosari Gounder',
  'Gounder - Rathinagiri Gounder',
  'Gounder - Sanku Vellala Gounder',
  'Gounder - Thondu Vellala Gounder',
  'Gounder - Tirumudi Vellala Gounder',
  'Gounder - Urali Gounder',
  'Gounder - Vettuva Gounder',
  'Gramani',
  'Hanafi',
  'Irular',
  'Jat',
  'Kaikolar',
  'Kallar',
  'Kallar Kula Thondaman',
  'Kambar',
  'Kammalar',
  'Kanakkan Padanna',
  'Kannada Saineegar',
  'Karaiyar',
  'Karkathar',
  'Karuneegar',
  'Kasukara',
  'Katesar',
  'Kavuthiyar',
  'Kerala Mudali',
  'Khoja',
  'Krishnavaka',
  'Kshatriya Raju',
  'Kulalar',
  'Kunchidigar',
  'Kuravar',
  'Kurumbar',
  'Kuruva',
  'Lebbai',
  'Malayar',
  'Malik',
  'Maniagar',
  'Mannan',
  'Mapila',
  'Maraicar',
  'Maravar',
  'Maruthuvar',
  'Mazhavaraayas',
  'Meenavar',
  'Memon',
  'Moopanar',
  'Mooppan',
  'Mudaliyar',
  'Mudugar',
  'Mughal',
  'Mukkulathor',
  'Mutharaiyar',
  'Muthuraja',
  'Nadar',
  'Nadar - Kongu Nadar',
  'Naicker',
  'Naicker - Vanniya Kula Kshatriyar',
  'Naidu - Balija Naidu',
  'Naidu - Kamma Naidu',
  'Naidu - Others',
  'Nanjil Mudali',
  'Nanjil Nattu Vellalar',
  'Nanjil Vellalar',
  'Nanjil pillai',
  'Navithar',
  'Nulayar',
  'Oddar',
  'Othuvaar',
  'Padmashali',
  'Pallar',
  'Panan',
  'Pandaram',
  'Paravan / Bharathar',
  'Parkavakulam / Udayar',
  'Parvatha Rajakulam',
  'Pathan',
  'Pattamkatti',
  'Pattinavar',
  'Pattusali',
  'Pillai - Illaththu Pillai',
  'Pillai - Kodikal Pillai',
  'Pillai - Others',
  'Pillai - Saiva Pillai Thanjavur',
  'Pillai - Saiva Pillai Tirunelveli',
  'Pulavar',
  'Pulaya / Cheruman',
  'Qureshi',
  'Rajput',
  'Reddy',
  'Rowther',
  'SC',
  'ST',
  'Salivagana',
  'Saliyar',
  'Samagar',
  'Sambava',
  'Senai Thalaivar',
  'Senguntha Mudaliyar',
  'Sengunthar',
  'Shafi',
  'Sheikh',
  'Siddiqui',
  'Sourashtra',
  'Syed',
  'Telugupatti',
  'Thandan',
  'Thevar',
  'Thondaman',
  'Thottiya Naicker',
  'Vadambar',
  'Vadugan',
  'Valluvan',
  'Vannar',
  'Vannia Kula Kshatriyar',
  'Veera Saivam',
  'Velan',
  'Vellalar - Aaru Nattu Vellala',
  'Vellalar - Archakari Vellala',
  'Vellalar - Arcot Vellala',
  'Vellalar - Cherakula Vellalar',
  'Vellalar - Devandra Kula Vellalar',
  'Vellalar - Isai Vellalar',
  'Vellalar - Kondaikatti Vellalar',
  'Vellalar - Kuga vellalar',
  'Vellalar - Nankudi Vellalar',
  'Vellalar - Others',
  'Vellalar - Pandiya Vellalar',
  'Vellalar - Podikara Vellalar',
  'Vellalar - Sozhiya Vellalar',
  'Vellalar - Thondai Mandala Vellalar',
  'Vellalar - Thuluva Vellala',
  'Vellalar - Veerakodi Vellala',
  'Vellalar - Virakodi vellala',
  'Vishwakarma',
  'Vokkaligar',
  'Yadava Naidu',
  'Yadavar',
  'kaladi',
];

export default casteList;
