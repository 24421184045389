import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../common/redux/store";
import ProfileView from "../components/ProfileView";
import {fetchMobile} from "../redux/thunks";
import Profile from "../../common/model/Profile";
import SocialNavigation from "../SocialNavigation";

const mapStateToProps = (state: RootState) => ({
  profile: state.social.profileList[state.social.currentProfileIndex]
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getNumber(profile: Profile) {
    dispatch(fetchMobile(profile))
  },
  navigateToCards() {
    SocialNavigation.navigateToProfileCardList()
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
