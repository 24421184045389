import React from "react";
import Modal from 'react-modal';
import ActionButton from "../../common/components/ActionButton";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onClick: () => void;
}

export default function PayModal (props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {/*<button style={{float: 'right', background: "#cb6ce6", border: "none", color: "white", borderRadius: 2, textAlign: "center", fontSize: 20, padding: 5}} onClick={props.closeModal}>x</button>*/}
      <div style={{fontSize: 20}}>Are you willing to pay Rs. 10 to get the mobile number of this profile?</div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: 10}}>
        <ActionButton text={"Yes"} onPress={() => {
          props.onClick()
          props.closeModal()
        }} />
        <ActionButton text={"No"} onPress={props.closeModal} />
      </div>
    </Modal>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: "#603693",
    border: "none"
  },
};
