import {AppDispatch, AppThunk, RootState} from "../../common/redux/store";
import RegisterNavigation from "../RegisterNavigation";
import Profile from "../../common/model/Profile";
import {changeJWT, changeModifiedProfile, changeProfile, changeRefreshToken} from "./slice";
import AuthenticationService from "../../common/service/AuthenticationService";
import AuthenticationResponse from "../../common/model/AuthenticationReponse";
import ProfileService from "../../common/service/ProfileService";
import {batch} from "react-redux";

export const fetchProfile = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state: RootState = getState();
    const userId: string | null = AuthenticationService.getUserId(state);
    if (userId) {
        const profile: Profile = await ProfileService.fetchProfile();
        batch(() => {
            dispatch(changeProfile(profile));
            dispatch(changeModifiedProfile(profile));
        });
        RegisterNavigation.navigateToProfile()
    } else {
        RegisterNavigation.navigateToLogin();
    }
};

export const saveProfile = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state: RootState = getState();
    let modifiedProfile: Profile = state.register.modifiedProfile;
    const userId: string | null = AuthenticationService.getUserId(state);
    if (userId) {
        modifiedProfile = Profile.setValidity(modifiedProfile);
        if (modifiedProfile.status === 'incomplete') {
            alert(
                'Profile Incomplete. Please fill all the mandatory fields (marked with *) to continue.',
            );
            return;
        } else if (modifiedProfile.status === 'inactive') {
            await ProfileService.saveProfile({
                ...modifiedProfile,
                id: userId,
            });
            alert(
                "Deactivation successful. Your profile has been successfully deactivated. Your profile won't be listed until you activate it back.",
            );
            dispatch(changeProfile(modifiedProfile));
        } else {
            const age: number = Math.abs(
                new Date(Date.now() - modifiedProfile.dateOfBirth).getUTCFullYear() -
                1970,
            );
            if (age < 21) {
                alert('You must be at least 21 years old to register');
                return;
            } else if (
                modifiedProfile.brothers !== undefined &&
                modifiedProfile.brothersMarried !== undefined &&
                modifiedProfile.brothers < modifiedProfile.brothersMarried
            ) {
                alert("Invalid data. The total number of brothers can't be less than the number of married brothers");
                return;
            } else if (
                modifiedProfile.sisters !== undefined &&
                modifiedProfile.sistersMarried !== undefined &&
                modifiedProfile.sisters < modifiedProfile.sistersMarried
            ) {
                alert("Invalid data. The total number of sisters can't be less than the number of married sisters");
                return;
            }
            await ProfileService.saveProfile({
                ...modifiedProfile,
                id: userId,
            });
            batch(() => {
                dispatch(changeProfile(modifiedProfile));
                dispatch(fetchProfile());
            });
            alert('Your profile has been saved successfully. You can close this website and wait for brides to contact you.');
        }
    } else {
        RegisterNavigation.navigateToLogin();
    }
}

export const sendOTP = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    try {
        const state: RootState = getState();
        await AuthenticationService.sendOTP(state.register.mobileNumber);
    } catch (error: any) {
        alert('Unable to send OTP: ' + error.message);
    }
}

export const attemptLogin = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    try {
        const state: RootState = getState();
        const authResponse: AuthenticationResponse = await AuthenticationService.attemptLoginMobile(
            state.register.mobileNumber,
            state.register.code,
        );
        dispatch(changeJWT(authResponse.jwt));
        dispatch(changeRefreshToken(authResponse.refreshToken));
        dispatch(fetchProfile())
    } catch (error: any) {
        alert('Unable to login: ' + error.message);
    }
}
