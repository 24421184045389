import React from "react";
import TwoBlockTitle from "../../common/components/TwoBlockTitle";
import InputBlock from "../../common/components/InputBlock";
import TextInput from "../../common/components/input/TextInput";
import ActionButton from "../../common/components/ActionButton";
import Container from "../../common/components/Container";

interface Props {
    code: string
    changeCode: (mobileNumber: string) => void
    time: number
    setTime: (time: number) => void
    setOTPSent: (otpSent: boolean) => void
    attemptLogin: () => void
    sendCode: () => void
}

export default function CodeEnter(props: Props) {
    return (
        <Container>
            <TwoBlockTitle />
            <InputBlock>
                <TextInput
                    label={"Confirmation Code"}
                    value={props.code}
                    onChange={props.changeCode}
                />
                <ActionButton text={"Enter"} onPress={props.attemptLogin}/>
                <ActionButton text={"Change mobile number"} onPress={() => { props.setOTPSent(false) }}/>
                {props.time ? (
                    <ResendOTPText time={props.time} />
                ) : (
                    <ActionButton text={"Resend OTP"} onPress={handleResendOTP(props)}/>
                )}
            </InputBlock>
        </Container>
    )
}

// @ts-ignore
function ResendOTPText({ time }) { return (
    <div style={styles.resendTimerText}>
        {'Resend in ' + time + ' seconds'}
    </div>
)}

function handleResendOTP(props: Props) {
    return () => {
        props.setTime(60);
        alert('Confirmation code resent');
        props.sendCode();
    }
}

const styles = {
    resendTimerText: {
        color: '#888',
        fontSize: 14
    },
    inputHeader: {
        marginBottom: 15,
        fontWeight: 700
    },
}