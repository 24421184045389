import React from 'react';

export default function TermsOfUse() {
  return (
    <div style={styles.container}>
      <h1>Terms of Use</h1>
      <p style={styles.text}>
        Welcome to Vennila matrimony (hereinafter referred to as the ‘website’), a
        practice test application released by Flutoz Software (OPC) Private
        Limited, CIN : U72900TN2021OPC141897,a company registered under the
        provisions of Indian companies act, 2013 and having its registered
        office at No.219/1, Seventeenth Street, South Balabagyanagar,
        Tirunelveli – 627001 Tamil Nadu, India (hereinafter referred to as ‘the
        company’). This document is an electronic record in terms of the
        Information Technology Act, 2000 and rules there pertaining to
        electronic records as applicable and amended. This electronic record is
        generated by a computer system and does not require any physical or
        digital signatures. This document is published in accordance with the
        provisions of Rule 3 (1) of the Information Technology (Intermediaries
        Guidelines) Rules, 2011 that require publishing the rules and
        regulations, privacy policy and the terms and conditions for access or
        usage of the website. Please read the following terms and conditions
        very carefully before using the website. Accessing or otherwise using
        the website implies your agreement to be bound by all these terms and
        conditions. If you do not want to be bound by any of the terms, you must
        not use the website. The terms and conditions also include the
        applicable policies which are incorporated herein by way of reference
        and as amended from time to time. In these terms and conditions,
        references to the website user (hereinafter referred to as User / You)
        shall mean the end user accessing the website, its contents or using its
        features and finally the test service provided by the website.
      </p>
      <h5 style={styles.subheading}>Scope</h5>
      <p style={styles.text}>
        You understand and acknowledge that the website provides a test service
        that aims at checking the user’s proficiency in selective topics of
        biology that may have relevance to various competitive examinations. The
        company does not give any assurance or guarantee that the questions
        contained in the test will appear in any of the competitive or
        eligibility examinations being conducted by any agency of private or
        government nature.
      </p>
      <h5 style={styles.subheading}>Eligibility:</h5>
      <p style={styles.text}>
        The website and the users are independent contractors, and no agency,
        partnership, joint venture, employer-employee or franchisor-franchisee
        relationship is intended or created by this Agreement. The user
        certifies that he/she is at least 18 (eighteen) years of age or has the
        consent of parent or legal guardian to avail the services of the
        website. The right of entry is reserved solely for Indian Nationals and
        Citizens.
      </p>
      <h5 style={styles.subheading}>Test Entry</h5>
      <p style={styles.text}>
        As the first step to avail the services of the website, you are supposed
        to provide your mobile number and email ID or that of a parent or legal
        guardian. The mobile number and email ID provided by you will not be
        verified at any stage before/during or after your participation at any
        stage or attending the test services. Any claim that the user intends to
        raise with the company will be entertained only if both the mobile
        number and email ID are true, correct and complete and only if you/your
        parent/your legal guardian legally possess the subscription of both the
        mobile number and email ID. You acknowledge and confirm that your entry
        into the website and the usage of website services is with the bonafide
        intention of testing your level of proficiency and knowledge in
        selective topics of biology that may have relevance to various
        competitive examinations and not otherwise. Website entry is restricted
        strictly to individuals only. Organizations, companies, businesses
        and/or individuals carrying on similar or competitive business cannot
        become users of the website
      </p>
      <h5 style={styles.subheading}>
        Role and Responsibility of the company and the user
      </h5>
      <p style={styles.text}>
        NeetBio conducts a test on Biology on selective topics. Before
        attempting every test, the user is supposed to pay the prescribed fees
        as fixed by the company from time to time for that specific test.The fee
        is to be paid only in indian rupees. The company is not responsible for
        any payment related issues. The fee paid is applicable to only one test
        that comprises 90 questions of MCQ (Multiple Choice Questions) type.
        Every test is for a duration of 90 minutes maximum. The user will be
        allowed to attend a test for a maximum of 90 minutes and after that the
        test gets completed automatically. However the user will be allowed to
        complete a test any time before 90 minutes through a specific
        submission. The results of the test will be displayed and the user will
        be allowed to review the correctness of his/her attempt. Though we have
        made every attempt to ensure the correctness of the subject information
        contained in every test, you understand and agree that the company is
        not responsible for any errors or omissions. You understand and agree
        that once you exit the test you will no longer get any information about
        the test you attended at any point of time later in any format and the
        paid service comes to an end once you exit a test. Neetbio users agree
        to use secured computer devices, software and networks in a private
        place for accessing the Neetbio services. The users shall not use any
        kind of Bots like web robots, Chatbot or any other automated
        processes/programs/scripts to use, communicate or respond in the
        website. The users shall not probe, scan or test the vulnerabilities of
        the Neetbio website or any network connected to the website nor breach
        the security measures instituted by the company.
      </p>
      <h5 style={styles.subheading}>Communication to Neetbio users</h5>
      <p style={styles.text}>
        Neetbio user hereby consents for receiving communication including
        promotional communications through any medium of communications such as
        electronic mails, calls, SMS or through Whatsapp messages from the
        company. Neetbio member confirms that the mobile number submitted to it
        is not registered with the Do Not Disturb / National Customer Preference
        Register and they shall not initiate any complaint. The user further
        confirms that even if the mobile number is registered with the telecom
        service provider under the category Do Not Disturb / National Customer
        Preference Register the calls from Neetbio shall not be treated as
        promotional calls.
      </p>
      <h5 style={styles.subheading}>Confidentiality</h5>
      <p style={styles.text}>
        Any feedback you provide to Neetbio shall be deemed to be
        non-confidential. The company shall be free to use such feedback /
        information on an unrestricted basis. Further, by submitting the
        feedback, you represent and warrant that (i) your feedback does not
        contain confidential or proprietary information of yourself or third
        parties; (ii) The user is not under any obligation of confidentiality,
        express or implied, with respect to the feedback; (iii) you are not
        entitled to any compensation or reimbursement of any kind from the
        company for the feedback under any circumstances.
      </p>
      <h5 style={styles.subheading}>Privacy of user</h5>
      <p style={styles.text}>
        To protect your privacy and understand our practices as amended from
        time to time, please read and follow our Privacy Policy which also
        governs your access to the website. The personal information / data
        provided to us by you during the course of usage of the website will be
        treated as confidential and in accordance with the Privacy policy and
        applicable laws and regulations. If you object to your information being
        transferred or used, please do not use the services of the website.
      </p>
      <h5 style={styles.subheading}>Grievance Cell</h5>
      <p style={styles.text}>
        In the event you have any grievance, you may then please be free to
        provide your concerns in writing or email us with digital signature to:
        email: ananth.flutoz@gmail.com; Grievance Officer: Mr. T. Anantha
        Padmanabhan; Contact Address :M/s. Flutoz Software (OPC) Private
        Limited,219/1, Seventeenth Street, South Balabagyanagar, Tirunelveli
        Junction,Tirunelveli 627 001
      </p>
      <p style={styles.text}>
        The Grievance office shall be available between 10 am till 6 pm Indian
        Standard Time from Monday to Friday excluding Saturday,Sunday and public
        holidays in India. The Grievance officer is appointed as per Rule 3 (11)
        of The Information Technology (Intermediaries Guidelines) Rules, 2011.
        Any and all complaints to the Grievance Officer shall be in writing.
      </p>
      <h5 style={styles.subheading}>Content Right</h5>
      <p style={styles.text}>
        You agree that all content of the website belongs to the company
        excluding your or third party content including advertisement on the
        website for which the company has requisite license/right in terms
        hereof to display the same on our website. The company reserves the
        right to proceed / initiate appropriate steps under the prevailing law
        against infringement by violators of its proprietary rights or for
        illegally copying, modifying, publishing, transmitting, distributing,
        performing, displaying, or selling any such proprietary information
        including using it for commercial purpose in any manner whatsoever.
      </p>
      <h5 style={styles.subheading}>Third Party website</h5>
      <p style={styles.text}>
        Neetbio user understand and agree that the company may allow third
        parties to put up advertisements on the website and the company does not
        control the contents of such third party advertisement on the website.
        Third party websites to which links are given in the website are not
        governed by these terms and conditions and all such third party websites
        are governed by their own terms and conditions for use and privacy
        policy, and in case of conflict the terms and conditions of the third
        party website shall prevail. The Company does not in any manner
        whatsoever authenticate, endorse, verify or certify these third party
        websites or any contents therein. Please note that a user shall click on
        links to third party websites at his / her sole risk and responsibility.
      </p>
      <h5 style={styles.subheading}>
        Refund Policy and Limitation of liability
      </h5>
      <p style={styles.text}>
        The company or its Office bearers or employees shall under no
        circumstances be liable or responsible to the Neetbio user or his/her
        authorized representative or any third party for any direct, indirect,
        special, exemplary, incidental, or consequential damages if any
        Notwithstanding anything to the contrary contained herein, the company’s
        liability to Neetbio user for any cause whatsoever, and regardless of
        the form of the action, will at all times be limited to the amount paid
        by the user to the company for the specific instance of test, and no
        further.
      </p>
      <h5 style={styles.subheading}>Class Action Suits</h5>
      <p style={styles.text}>
        You acknowledge and confirm that you will not bring or participate in
        any class action or other class proceeding in connection with any
        dispute with the company. Further neither you nor the company agrees to
        class arbitration.
      </p>
      <h5 style={styles.subheading}>Disclaimer</h5>
      <p style={styles.text}>
        Your access to and use of the Neetbio Services or any content is at your
        own risk. You understand and agree that the Neetbio services are
        provided to you on an “as is” and “as available” basis. WITHOUT LIMITING
        THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, THE COMPANY
        DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. The company does
        not warrant that the website, its services/ servers, are free of viruses
        or other harmful components. The company will not be liable for any
        damages of any kind arising from the use of this website, including, but
        not limited to direct, indirect, incidental, punitive, and consequential
        damages. The company does not give any implied or explicit guarantee or
        warranty of excellence in biology by using the test services of the
        company Notwithstanding anything contrary contained anywhere, under no
        circumstances, the company shall be held responsible or liable
        whatsoever or howsoever, arising out of, relating to or connected with:
      </p>
      <p style={styles.text}>i. any act or omission done by the company ;</p>
      <p style={styles.text}>
        ii. any untrue or incorrect information submitted by you or on your
        behalf;
      </p>
      <p style={styles.text}>
        iii. any decision taken by you or on your behalf or any consequences
        thereof, based on any content information provided by the company
      </p>
      <p style={styles.text}>
        iv. any cyber crime attempted or committed by anyone and
      </p>
      <p style={styles.text}>
        v. any incident of force-majeure or 'act of god'.
      </p>
      <p style={styles.text}>
        vi. any issue already stated in these terms and conditions including
        limitation of liability clause of these terms and conditions.
      </p>
      <p style={styles.text}>
        vii. any issues relating to any technical malfunction of any
        telecommunication network, software, hardware failures, network
        congestion, denial of service, failure due to spamming or any
        combination of the above.
      </p>
      <p style={styles.text}>
        You expressly agree that your use of this Neetbio website service is at
        your sole risk. We are not liable to you for any damage or alteration to
        your equipment including but not limited to computers as a result of use
        of the website.
      </p>
      <h5 style={styles.subheading}>Indemnity</h5>
      <p style={styles.text}>
        By using our Neetbio services you agree to defend, indemnify, and hold
        harmless the company, its subsidiaries, affiliates, Directors, officers,
        agents, and other partners and employees, fully indemnified and harmless
        from any loss, damage, liability, claim, or demand, including reasonable
        attorney's fees, made by any person through improper use of the service
        provided by Neetbio website. This defence and indemnification obligation
        will survive in perpetuity.
      </p>
      <h5 style={styles.subheading}>Jurisdiction and Applicable Law</h5>
      <p style={styles.text}>
        The entry and use of all Neetbio website services are deemed to have
        been entered into within the territorial Jurisdiction of Tirunelveli,
        India. The Neetbio users unconditionally agree that all such disputes
        and / or differences, if any, shall be governed by the Laws of India and
        submitting to the exclusive Jurisdiction of the appropriate court of law
        in Tirunelveli, Tamil Nadu, India.
      </p>
    </div>
  );
}

const styles = {
  text: {
    color: 'white',
    textAlign: 'justify' as 'justify',
    fontFamily: "Teko, 'normal'",
    fontWeight: 200,
    letterSpacing: 1,
    fontSize: '1.2vw',
    lineHeight: 1,
  },
  subheading: {
    // color: '#0ca950',
    color: 'white',
    fontFamily: "Teko, 'normal'",
    letterSpacing: 1,
    fontSize: '1.5vw',
  },
  container: {
    color: '#0ca950',
    backgroundColor: '#323337',
    padding: 50,
    minHeight: '100%',
  },
};
