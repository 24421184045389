import {v4 as uuid} from "uuid";

interface InitialState {
  location: string;
  id: string;
  loading: boolean;
}

const initialState: InitialState = {
  location: "/",
  id: uuid(),
  loading: false,
};

export default initialState;
