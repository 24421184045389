import React from 'react';

export default function AboutUs() {
  return (
    <div style={styles.container}>
      <h1>About Us</h1>
      <br />
      <p style={styles.text}>
        Vennila Matrimony from Flutoz Software is a website that provides world class
        matrimony services at affordable prices.
      </p>
    </div>
  );
}

const styles = {
  text: {
    color: 'white',
    textAlign: 'justify' as 'justify',
    fontWeight: 200,
    letterSpacing: 1,
    fontSize: '2vh',
  },
  container: {
    color: '#0ca950',
    backgroundColor: '#323337',
    padding: 50,
    minHeight: '100%',
  },
};
