const qualificationList: string[] = [
  'Below S.S.L.C.',
  'S.S.L.C.',
  'H.Sc.',
  'Certification',
  'Diploma',
  'B.A.',
  'B.Sc',
  'B.Com',
  'B.B.A.',
  'B.C.A.',
  'B.H.M.',
  'B.F.Sc.',
  'B.Lib.Sc.',
  'B.P.Ed.',
  'B.F.A.',
  'B.Pharm.',
  'B.V.Sc.',
  'B.E./B.Tech./B.Arch.',
  'B.D.S.',
  'M.B.B.S.',
  'B.H.M.S./B.A.M.S/B.S.M.S.',
  'B.U.M.S./B.N.Y.S.',
  'B.L.',
  'M.A.',
  'M.Sc.',
  'M.Com.',
  'M.B.A.',
  'M.C.A.',
  'M.H.M.',
  'M.F.Sc.',
  'M.Lib.Sc.',
  'M.P.Ed.',
  'M.F.A.',
  'M.Pharm.',
  'M.V.Sc.',
  'M.E./M.Tech./M.S./M.Arch.',
  'M.S./M.D.',
  'M.L.',
  'M.Phil.',
  'Ph.D',
  'ICWAI / CA / CS / CFA',
  'Others',
];

export default qualificationList;
