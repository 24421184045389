import store from "../common/redux/store";
import {changeLocation} from "../common/redux/slice";

class SocialNavigation {
  navigateToProfileView() {
    store.dispatch(changeLocation("/profile"));
  }
  navigateToFilter() {
    store.dispatch(changeLocation("/filter"))
  }
  navigateToProfileCardList() {
    store.dispatch(changeLocation("/social"))
  }
}

export default new SocialNavigation()