import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../common/redux/store";
import {attemptLogin, sendOTP} from "../redux/thunks";
import {changeCode, changeMobileNumber} from "../redux/slice";
import Login from "../components/Login";

const mapStateToProps = (state: RootState) => ({
    mobileNumber: state.register.mobileNumber,
    code: state.register.code,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeMobileNumber(mobileNumber: string) {
        dispatch(changeMobileNumber(mobileNumber));
    },
    changeCode(code: string) {
        dispatch(changeCode(code));
    },
    attemptLogin() {
        dispatch(attemptLogin());
    },
    sendCode() {
        console.log('Hitting API to send OTP')
        dispatch(sendOTP());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
